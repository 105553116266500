import { fonts } from '../fonts'
import defaultTheme from '@bluheadless/ui/src/theme/default'

const Tab = {
	MuiTab: {
		styleOverrides: {
			root: {
				...fonts.medium,
				fontSize: 16,
				letterSpacing: 0,
				lineHeight: 1,
				minHeight: 0,
				minWidth: 136,
				[defaultTheme.breakpoints.up('md')]: {
					minWidth: 146,
					padding: defaultTheme.spacing(3.75, 0),
				},
				'&.Mui-selected': {
					...fonts.bold,
				},
			},
		},
	},
}

export default Tab
