const Tabs = {
	MuiTabs: {
		styleOverrides: {
			root: {
				minHeight: 0,
			},
		},
	},
}

export default Tabs
