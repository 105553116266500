import { fonts } from '../fonts'
import defaultTheme from '@bluheadless/ui/src/theme/default'

const Button = {
	MuiButton: {
		styleOverrides: {
			contained: {
				...fonts.condensedBold,
				borderRadius: 0,
				textAlign: 'center',
			},
			containedPrimary: {
				minHeight: 0,
			},
			containedSecondary: {
				backgroundColor: '#fff',
				color: '#000',
				minHeight: 0,
				'&:active, &:focus, &:hover': {
					backgroundColor: 'rgba(235, 233, 233, 0.85)',
					color: '#000',
				},
				'&:hover': {
					color: '#000',
				},
			},
			outlined: {
				...fonts.condensedBold,
				borderRadius: 0,
				textAlign: 'center',
			},
			text: {
				...fonts.medium,
			},
			textNoLink: {
				...fonts.medium,
			},
			widget: {
				...fonts.condensedBold,
				backgroundColor: 'transparent',
				border: '2px solid #fff',
				borderRadius: 0,
				color: '#fff',
				fontSize: 20,
				letterSpacing: -0.4,
				lineHeight: 1,
				minWidth: 170,
				padding: defaultTheme.spacing('13px', 5),
				[defaultTheme.breakpoints.up('md')]: {
					fontSize: 14,
					letterSpacing: 0,
					padding: defaultTheme.spacing('9px', 5),
				},
				'&:hover': {
					backgroundColor: 'rgba(20, 22, 22, 0.85)',
				},
			},
		},
	},
}

export default Button
