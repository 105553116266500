import GiftIcon from '@bluheadless/ui/src/atoms/icons/gift-icon'

const BHFooterBar = {
	BHFooterBar: {
		defaultProps: {
			actionBarHeight: 75,
			StartIcon: GiftIcon,
		},
	},
}

export default BHFooterBar
