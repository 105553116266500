import { fonts } from '../fonts'

const CssBaseline = {
	MuiCssBaseline: {
		styleOverrides: {
			html: {
				'--color-pink': '#ff00b6',
			},
			body: {
				...fonts.medium,
				color: '#000',
			},
		},
	},
}

export default CssBaseline
