import { styled } from '@mui/system'
import { LinearProgress as MuiLinearProgress } from '@mui/material'

const shouldForwardProp = (prop) => !['show'].includes(prop)

export const Root = styled('div', { shouldForwardProp })`
	pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
	display: flex;
	${({ theme }) => theme.breakpoints.down('sm')} {
		display: none;
	}
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999;
	background-color: ${({ theme }) => theme.palette.white.main};
`

export const Inner = styled('div')`
	max-width: 150px;
	min-width: 150px;
`

export const LinearProgress = styled(MuiLinearProgress)`
	.MuiLinearProgress-bar1Indeterminate,
	.MuiLinearProgress-bar2Indeterminate {
		background-color: var(--color-pink);
	}
`
