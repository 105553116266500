const BHCrossSell = {
	BHCrossSell: {
		defaultProps: {
			sliderProps: {
				controlsPosition: 'default',
				breakpoints: {
					xs: { spaceBetween: 10 },
					md: {
						spaceBetween: 20,
					},
				},
				spaceBetween: 10,
			},
		},
	},
}

export default BHCrossSell
